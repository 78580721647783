<template>
  <a-modal :visible="visible" :title="title" @cancel="onCancel" width="1000px" class="tendering-model">
    <a-form-model
      :model="form"
      ref="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
      style="overflow: auto; height: 600px"
    >
      <div class="basic-form">
        <div class="form-title">基本信息</div>
        <a-form-model-item label="项目公告标题" :prop="'title'" :rules="{ required: true, message: '不能为空' }">
          <a-input v-model="form.title" placeholder="项目公告标题" :maxlength="50" />
        </a-form-model-item>
        <a-form-model-item label="项目类型" :prop="'type'" :rules="{ required: true, message: '不能为空' }">
          <a-select allowClear ref="select" :options="typeList" v-model="form.type" placeholder="请选择"></a-select>
        </a-form-model-item>
        <a-form-model-item label="项目地点" :prop="'place'" :rules="{ required: true, message: '不能为空' }">
          <a-cascader
            :options="areaOption"
            v-model="form.place"
            :fieldNames="{
              label: 'district',
              value: 'district',
              children: 'children',
            }"
            placeholder="请选择项目地点"
          />
        </a-form-model-item>
        <a-form-model-item label="详细描述" :prop="'comment'" :rules="{ required: true, message: '不能为空' }">
          <vEditor v-model="form.comment" />
        </a-form-model-item>
        <a-form-model-item label="附件上传" class="form-item">
          <a-upload
            name="file"
            :multiple="true"
            :action="action"
            :headers="headers"
            @change="handleFileListChange"
            :beforeUpload="uploadBefore"
            :remove="handleFileListRemove"
            :fileList="fileList"
            class="avatar-uploader"
            accept=".doc, .xlsx, .pdf"
          >
            <a-button> <a-icon type="upload" />上传附件（.doc, .xlsx, .pdf） </a-button>
          </a-upload>
        </a-form-model-item>
      </div>
      <div class="basic-form" style="margin-top: 20px">
        <div class="form-title">响应企业应具备资质</div>
        <a-form-model-item label="资质要求">
          <vEditor v-model="form.ask" />
        </a-form-model-item>
        <div class="basic-form-item">
          <div class="basic-form-item-title">应具有资质附件：</div>
          <div class="basic-form-item-content">
            <div class="add-box">
              <a-button type="primary" @click="handleAddFile">新增资质附件</a-button>
              <div class="tips">
                添加资质附件要求后，企业需上传对应附件信息后方可进行响应报名，您可在招投标管理-站内项目承接公告-项目-操作-查看应标意向企业中
                查看。
              </div>
            </div>
            <template v-for="(item, index) in form.fileList">
              <a-form-model-item :label="`资质附件${index + 1}`" :key="index">
                <a-input v-model="item.title" placeholder="请输入资质附件名称" />
                <a-icon type="delete" class="delete-icon" @click="handleDelFile(index)" />
              </a-form-model-item>
            </template>
          </div>
        </div>
      </div>
    </a-form-model>
    <template #footer>
      <a-button @click="onCancel">取消</a-button>
      <a-button type="primary" @click="handleSubmit(0)" :loading="loadingSave">保存</a-button>
      <a-button type="primary" @click="handleSubmit(1)" :loading="loadingRelease">发布</a-button>
    </template>
  </a-modal>
</template>

<script>
import { AnnouncementControllerPost, AnnouncementControllerPut } from '@/api/TenderingBidding'
import vEditor from '@/components/common/v-editor/index'
import { getAreaTree } from '@/api/workDemand'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import VueCookeis from 'vue-cookies'
import { typeList } from './dict'

const formData = {
  title: undefined,
  comment: undefined,
  place: undefined,
  type: undefined,
  fileUrl: [],
  fileList: [],
  ask: ''
}

export default {
  name: 'TenderingForm',
  components: {
    vEditor
  },
  data () {
    return {
      loadingSave: false,
      loadingRelease: false,
      areaOption: [],
      visible: false,
      form: {
        ...formData
      },
      edit: false,
      typeList,
      fileList: [],
      action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
      headers: {
        token: VueCookeis.get(ACCESS_TOKEN),
        clientId: process.env.VUE_APP_CLIENT_ID
      }
    }
  },
  computed: {
    title () {
      return this.edit ? '招标公告编辑' : '招标公告新增'
    }
  },
  created () {
    getAreaTree() // 城市
      .then((res) => {
        this.areaOption = res
      })
  },
  methods: {
    createModel () {
      this.visible = true
      this.edit = false
      this.$set(this, 'form', { ...formData, fileList: [] })
    },
    editModel (model) {
      const fileList = JSON.parse(model.fileUrl).map((item) => ({
        ...item,
        name: item.fileName
      }))
      this.$set(this, 'form', {
        ...model,
        place: model.place.split(','),
        fileUrl: fileList,
        fileList: model.fileList || []
      })
      this.$set(this, 'fileList', fileList)
      this.edit = true
      this.visible = true
    },
    uploadBefore (file) {
      const { size, name } = file
      const typeList = ['pdf', 'xlsx', 'doc']
      const dotIdx = name.lastIndexOf('.') // 获取最后一个.的位置
      const suffix = name.substr(dotIdx + 1) // 获取文件后缀
      if (!typeList.includes(suffix.toLowerCase())) {
        this.$message.warning('文件格式不正确')
        return false
      } else if (size > 100 * 1024 * 1024) {
        this.$message.warning('文件大小不要超过100MB')
        return false
      } else {
        return true
      }
    },
    handleFileListChange ({ file, fileList, event }) {
      this.fileList = fileList
      if (file.status === 'done') {
        const index = fileList.findIndex((item) => item.uid === file.uid)
        this.form.fileUrl.splice(index, 1, {
          path: file.response.path,
          fileName: file.response.fileName,
          uid: file.uid
        })
      }
    },
    handleFileListRemove (file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid)
      this.form.fileUrl = this.form.fileUrl.filter((item) => item !== file.uid)
      return true
    },
    onCancel () {
      this.visible = false
      this.$refs.form.resetFields()
      this.$refs.form.$el.scrollTop = 0
      this.loadingSave = false
      this.loadingRelease = false
      this.fileList = []
    },
    handleSubmit (val) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const userInfo = this.$store.getters.userInfo
          const model = {
            ...this.form,
            fileUrl: JSON.stringify(this.form.fileUrl),
            place: this.form.place.join(','),
            publishEnterprise: userInfo.enterpriseInfoDto.enterpriseId,
            enterpriseName: userInfo.enterpriseInfoDto.enterpriseName,
            publishUser: userInfo.username,
            fileList: this.form.fileList.filter((item) => !!item.title)
          }
          if (val === 0) {
            this.loadingSave = true
          } else {
            this.loadingRelease = true
          }
          const fun = this.edit ? AnnouncementControllerPut : AnnouncementControllerPost
          fun({ ...model, publishStatus: val })
            .then((res) => {
              this.$message.success((val === 0 ? '保存' : '发布') + '成功')
              this.$emit('success')
              this.onCancel()
            })
            .finally(() => {
              this.loadingSave = false
              this.loadingRelease = false
            })
        }
      })
    },
    handleAddFile () {
      this.form.fileList.push({ title: '' })
    },
    handleDelFile (index) {
      this.form.fileList.splice(index, 1)
    }
  }
}
</script>

<style lang="less">
.tendering-model {
  .basic-form {
    border: 1px solid #d9d9d9;
    .form-title {
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      color: #3d3d3d;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 30px;
    }
    &-item {
      margin-top: 24px;
      display: flex;
      align-items: baseline;
      width: 930px;
      &-title {
        width: 155px;
        text-align: right;
      }
      &-content {
        width: 700px;
        border: 1px solid #d9d9d9;
        position: relative;
        top: -15px;
        padding: 20px 0;
      }
    }
  }
  .add-box {
    padding: 20px;
    padding-top: 0;
    display: flex;
    align-items: center;
    .tips {
      color: #999999;
      margin-left: 20px;
      font-size: 12px;
    }
  }
  .delete-icon {
    position: absolute;
    right: -30px;
    top: 3px;
    color: #e21414;
    cursor: pointer;
  }
}
</style>
